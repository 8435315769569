// Code generated by backend/lib/tasks/static_code_converters.rake; DO NOT EDIT.

export const codeWorkPatternOptions = [
  {
    "id": 1,
    "code": "full",
    "name": "常勤（フルタイム勤務）"
  },
  {
    "id": 2,
    "code": "part",
    "name": "非常勤"
  }
]
