<template>
  <div class="admin-user-create">
    <AHeading :level="1" class="admin-user-create__heading">従業員一括登録</AHeading>
    <ALink back>&lt; 戻る</ALink>
    <OUserCrateCsv />
  </div>
</template>

<script>
import AHeading from '@/components/atoms/ATypography/AHeading/index.vue'
import ALink from '@/components/atoms/ATypography/ALink/index.vue'
import OUserCrateCsv from '@/components/organisms/OUserCreateCsv/index.vue'

export default {
  name: 'AdminUserCreate',
  components: {
    AHeading,
    ALink,
    OUserCrateCsv
  }
}
</script>

<style lang="scss" scoped>
.admin-user-create {
  &__heading {
    margin: 20px auto;
  }
}
</style>
