// Code generated by backend/lib/tasks/static_code_converters.rake; DO NOT EDIT.

export const codeProfessionOptions = [
  {
    "id": 1,
    "code": "care_worker",
    "name": "介護職"
  },
  {
    "id": 2,
    "code": "nursing",
    "name": "看護職"
  },
  {
    "id": 3,
    "code": "rehabilitation",
    "name": "リハビリ職"
  },
  {
    "id": 4,
    "code": "counselor",
    "name": "相談員"
  },
  {
    "id": 5,
    "code": "office_worker",
    "name": "事務職"
  },
  {
    "id": 6,
    "code": "manager",
    "name": "管理職"
  },
  {
    "id": 7,
    "code": "other",
    "name": "その他"
  }
]
