// Code generated by backend/lib/tasks/static_code_converters.rake; DO NOT EDIT.

export const codeQualificationOptions = [
  {
    "id": 1,
    "code": "Q001",
    "name": "正看護師"
  },
  {
    "id": 2,
    "code": "Q002",
    "name": "准看護師"
  },
  {
    "id": 3,
    "code": "Q003",
    "name": "社会福祉士"
  },
  {
    "id": 4,
    "code": "Q004",
    "name": "介護福祉士"
  },
  {
    "id": 5,
    "code": "Q005",
    "name": "精神保健福祉士"
  },
  {
    "id": 6,
    "code": "Q006",
    "name": "保育士"
  },
  {
    "id": 7,
    "code": "Q007",
    "name": "栄養士"
  },
  {
    "id": 8,
    "code": "Q008",
    "name": "ケアマネージャー"
  },
  {
    "id": 9,
    "code": "Q009",
    "name": "ホームヘルパー"
  },
  {
    "id": 10,
    "code": "Q010",
    "name": "福祉環境コーディネーター"
  },
  {
    "id": 11,
    "code": "Q011",
    "name": "福祉用具専門相談員"
  },
  {
    "id": 12,
    "code": "Q012",
    "name": "医療事務管理士"
  },
  {
    "id": 13,
    "code": "Q013",
    "name": "社会福祉主事"
  },
  {
    "id": 14,
    "code": "Q014",
    "name": "サービス管理責任者"
  },
  {
    "id": 15,
    "code": "Q015",
    "name": "生活指導員"
  },
  {
    "id": 16,
    "code": "Q016",
    "name": "職業指導員"
  },
  {
    "id": 17,
    "code": "Q017",
    "name": "介護員"
  },
  {
    "id": 18,
    "code": "Q018",
    "name": "介護初任者研修"
  },
  {
    "id": 19,
    "code": "Q019",
    "name": "介護実務者研修"
  }
]
