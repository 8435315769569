<template>
  <div class="o-users-create-csv">
    <input @change="fileChange" type="file" id="file_input_expense" name="file_input_expense">
    <div class="o-user-create-csv__table" v-if="workers.length">
      <div class="o-user-create-csv__table-header">
        <AHeading :level="2">プレビュー</AHeading>
        <AButton
            @click="clickButton" class="o-user-create-csv__button">登録</AButton>
      </div>
      <v-data-table :headers="headers" :items="workers" class="elevation-1">
        <template slot="items" slot-scope="props">
          <td class="text-xs-right">{{ props.item }}</td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AHeading from '@/components/atoms/ATypography/AHeading/index.vue'
import AButton from '@/components/atoms/AButton/index.vue'
import { codeEmploymentStatusOptions } from '@/config/staticSelectCodeEmploymentStatus'
import { codePositionOptions } from '@/config/staticSelectCodePosition'
import { codeProfessionOptions } from '@/config/staticSelectCodeProfession'
import { codeQualificationOptions } from '@/config/staticSelectCodeQualification'
import { codeWorkPatternOptions } from '@/config/staticSelectCodeWorkPattern'

export default {
  name: 'OUserCrateCsv',
  components: {
    AHeading,
    AButton
  },
  data: () => ({
    headers: [
      { text: "氏名", align: "left", sortable: false, value: "name" },
      { text: "ふりがな", align: "left", sortable: false, value: "nameKana" },
      { text: "ログインID", align: "left", sortable: false, value: "loginId" },
      { text: "パスワード", align: "left", sortable: false, value: "password" },
      { text: "入社年月日", align: "left", sortable: false, value: "joinedCompanyDate" },
      { text: "誕生日", align: "left", sortable: false, value: "birthday" },
      { text: "雇用形態", align: "left", sortable: false, value: "employmentStatus" },
      { text: "勤務形態", align: "left", sortable: false, value: "workPattern" },
      { text: "職種", align: "left", sortable: false, value: "profession" },
      { text: "役職", align: "left", sortable: false, value: "position" },
      { text: "在籍状況(1:在籍/2:退職)", align: "left", sortable: false, value: "enrollmentStatus" },
      { text: "保有資格", align: "left", sortable: false, value: "qualification" },
      { text: "ログイン可否(true:可能/false:禁止)", align: "left", sortable: false, value: "loginEnabled" },
    ],
    workers: []
  }),
  methods: {
    ...mapActions('adminUser', ['createUsers']),
    fileChange(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      const workers = [];

      const loadFunc = () => {
        let lines = reader.result.split(/\r\n|\n|\r/);
        lines.shift();
        lines.forEach(element => {
          const workerData = element.split(",");
          if (workerData.length != 13) return;

          const worker = {
            name: workerData[0],
            nameKana: workerData[1],
            loginId: workerData[2],
            password: workerData[3],
            joinedCompanyDate: workerData[4],
            birthday: workerData[5],
            employmentStatus: workerData[6],
            workPattern: workerData[7],
            profession: workerData[8],
            position: workerData[9],
            enrollmentStatus: workerData[10],
            qualification: workerData[11],
            loginEnabled: workerData[12],
          };
          workers.push(worker);
        });
        this.workers = workers;
      };

      reader.onload = loadFunc;

      reader.readAsText(file);
    },
    clickButton() {
      const users = [];
      this.workers.forEach(work => {
        const employmentStatus = codeEmploymentStatusOptions.filter(x => x.name === work.employmentStatus)
        const profession = codeProfessionOptions.filter(x => x.name === work.profession)
        const position = codePositionOptions.filter(x => x.name ===work.position)
        const qualification = codeQualificationOptions.filter(x => x.name === work.qualification)
        const workPattern = codeWorkPatternOptions.filter(x => x.name === work.workPattern)
        const user = {
          officeId: Number(this.$route.params.officeId),
          name: work.name,
          nameKana: work.nameKana,
          loginId: work.loginId,
          password: work.password,
          joinedCompanyDate: work.joinedCompanyDate,
          birthday: work.birthday,
          employmentStatus: employmentStatus ? employmentStatus[0].code : undefined,
          workPattern: workPattern ? workPattern[0].code : undefined,
          profession: profession ? profession[0].code : undefined,
          position: position ? position[0].code : undefined,
          enrollmentStatus: Number(work.enrollmentStatus),
          qualification: qualification ? qualification[0].code : undefined,
          loginEnabled: Boolean(work.loginEnabled)
        };
        users.push(user)
      })
      // ユーザ登録
      try {
        this.createUsers(users)
      } catch (e) {
        alert(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.o-user-create-csv {
  &__table-header {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 12px;
  }
  &__button {
    display: inline-block;
    width: 100px;
  }
}
</style>
