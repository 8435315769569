// Code generated by backend/lib/tasks/static_code_converters.rake; DO NOT EDIT.

export const codeEmploymentStatusOptions = [
  {
    "id": 1,
    "code": "regular",
    "name": "正社員"
  },
  {
    "id": 2,
    "code": "contractor",
    "name": "契約社員"
  },
  {
    "id": 3,
    "code": "rehire",
    "name": "嘱託社員"
  },
  {
    "id": 4,
    "code": "arubaito",
    "name": "アルバイト"
  },
  {
    "id": 5,
    "code": "part",
    "name": "パートタイマー"
  },
  {
    "id": 6,
    "code": "tempolary",
    "name": "派遣社員"
  },
  {
    "id": 7,
    "code": "other",
    "name": "その他"
  }
]
